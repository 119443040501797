import Box from "@mui/material/Box";
import {FC, useCallback, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Modal} from "@plumeuk/shapeshift-common/v2";
import {Button, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Dayjs} from "dayjs";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";

interface IProps {
	open: boolean,
	courseId: number,
	onClose: (reload?: boolean) => void,
	selected: number[]
}

const useStyles = makeStyles()((theme) => ({
	adminBulkEnrollmentModal: {
		padding: theme.spacing(3),
		display: "flex",
		gap: theme.spacing(2),
		flexDirection: "column"
	}
}));

type IAdminUserBulkActionAddToTrainingFeedResponse = any //to do

export const AdminBulkEnrollmentModal: FC<IProps> = ({open, courseId, selected, onClose}) => {
	const {classes} = useStyles();
	const [enrollType, setEnrollType] = useState<"prioritize" | "due-date">("prioritize");
	const [dueDate, setDueDate] = useState<Dayjs | null>(null);
	const {notify} = useContext(NotificationContext);
	const [dueDateEnrollResponse, submitDueDateApi] = useApi<IAdminUserBulkActionAddToTrainingFeedResponse[]>()

	useEffect(() => {
		setEnrollType("prioritize")
		setDueDate(null)
	}, [open])

	const handleSubmitDueDateEnroll = useCallback(() => {
		if(!open || !dueDate)
			return;

		submitDueDateApi({
			url: "/api/user/bulk/enroll",
			method: "POST",
			data: {
				course: courseId,
				dueDate,
				users: selected
			}
		})
	}, [dueDate, courseId, open])

	useEffect(() => {
		if(dueDateEnrollResponse.statusCode === 200){
			notify("", "Successfully enrolled users", INotificationSeverity.success, 5000)
			onClose(true)
		}
		else if(dueDateEnrollResponse.statusCode){
			notify("", "Failed to enroll users", INotificationSeverity.error, 5000)
			onClose()
		}
	}, [dueDateEnrollResponse])

	const handleSubmitPrioritizeEnroll = useCallback(() => {
		if(!open)
			return;

		submitDueDateApi({
			url: "/api/user/bulk/enroll/prioritise",
			method: "POST",
			data: {
				course: courseId,
				users: selected
			}
		})
	}, [open, courseId])

	return (
		<Modal
			open={open}
			footerButtons={[
				<Button key={1} onClick={() => onClose()}>CANCEL</Button>,
				<Button key={2} onClick={() => enrollType === "due-date"
					? handleSubmitDueDateEnroll()
					: handleSubmitPrioritizeEnroll()}>CONFIRM</Button>
			]}
			onClose={() => onClose()}
			aria-labelledby="modal-modal-title"
			title="Confirm Bulk Enrollment"
			aria-describedby="modal-modal-description"
		>
			<Box className={classes.adminBulkEnrollmentModal}>
				<Typography variant="h5">Confirm Bulk Enrollment</Typography>
				<Typography paddingTop={1}>
					Manage your training course by adding a due date or moving it to the top of your employee&apos;s training feed.<br />
					<br />
					Important: Please note that prioritizing courses may push other items down the list and impact other courses due dates.
				</Typography>
				<Typography>
					<li>[{selected.length}] unenrolled users selected</li>
				</Typography>
				<RadioGroup
					row
					value={enrollType}
					onChange={(e) => setEnrollType((e.target as HTMLInputElement).value as "prioritize" | "due-date")}
					aria-labelledby="demo-row-radio-buttons-group-label"
					name="row-radio-buttons-group"
				>
					<FormControlLabel value="prioritize" control={<Radio />} label="Prioritize in Training Feed" />
					<FormControlLabel value="due-date" control={<Radio />} label="Due Date" />
				</RadioGroup>
				{enrollType === "due-date" && <LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						label="Date Due"
						sx={{width: 300}}
						value={dueDate}
						onChange={(newValue) => newValue && setDueDate(newValue)}
					/>
				</LocalizationProvider>}
			</Box>
		</Modal>
	);
}