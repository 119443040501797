import {FC} from "react";
import {Box, BoxProps, ThemeProvider} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {useApi} from "@plumeuk/shapeshift-identity";
import {IAdminUsersLessonsResponseEntry, ILocalizationEntity, ILibraryCourse} from "@plumeuk/shapeshift-types";
import {AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/admin";
import {useNavigate} from "react-router-dom";
import {convertSecondsToHHMMSS} from "../../../utils";
import {AdminHeader} from "../../ManagerView/AdminHeader";
import {GRID_CHECKBOX_SELECTION_COL_DEF} from "@mui/x-data-grid-pro";
import {palette} from "../../../constants"
import {adminTheme} from "../../../templates/adminTheme";

const useStyles = makeStyles()((theme) => ({
	contentBox: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(2.5),
		backgroundColor: palette.greyDark,
		borderWidth: 0,
		borderRadius: theme.borderRadius.small,
		"& .MuiButton-text": {
			color: theme.palette.primary.main,
			border: "0px solid",
			borderRadius: "0",
			fontWeight: "600",
			fontSize: "0.8125rem",
			textTransform: "uppercase",
			padding: "12px 5px 12px",
			letterSpacing: "0.025rem",
			"&:hover": {
				background: "transparent",
				opacity: "0.8"
			},
			"& span": {
				marginRight: 0,
				background: "transparent"
			},
			"& svg": {
				marginRight: "8px",
				"& path": {
					fill: `${theme.palette.primary.main} !important`
				}
			},
			"&:disabled": {
				color: theme.palette.primary.main,
				opacity: 0.5,
				"& svg": {
					"& path": {
						fill: theme.palette.primary.main
					}
				}
			}
		},
		"& [class*='customToolbar']": {
			display: "none !important"
		}
	},
	lessonsAdminTable: {
		padding: 0
	}
}));

interface ICustomProps {
	course?: ILibraryCourse;
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

const headCells = (locales?: ILocalizationEntity[]): AdminTableProps<"lesson", IAdminUsersLessonsResponseEntry>["columns"] => ([
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		type: "string",
		hideable: false
	},
	{
		field: "title",
		type: "string",
		flex: 1,
		headerName: "Title"
	},
	{
		field: "videoDuration",
		type: "string",
		flex: 1,
		headerName: "Duration",
		valueGetter: (e) => e.value ? convertSecondsToHHMMSS(e.value) : ""
	},
	{
		field: "updatedAt",
		type: "string",
		flex: 1,
		headerName: "Last Modified",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "languages",
		type: "string",
		flex: 1,
		headerName: "Supported Languages",
		valueGetter: ({value}: {value: IAdminUsersLessonsResponseEntry["languages"]}) => value?.map(e => locales?.find(x => x.code === e)?.name)?.join(", ")
	}
]);


export const LessonsTab:FC<IProps> = ({course, ...baseProps}) => {
	const {classes} = useStyles();
	const [locales] = useApi<ILocalizationEntity[]>("/api/settings/i18n");
	const navigate = useNavigate();

	return <Box {...baseProps}>
		<Box className={classes.contentBox}>
			<AdminHeader title={`${course?.title} Lessons`} />
			<ThemeProvider theme={adminTheme}>
				<AdminTable<"lesson", IAdminUsersLessonsResponseEntry>
					className={classes.lessonsAdminTable}
					url={`/api/strapi-plugin-shapeshift-lms/cohort/course/${course?.id}/lessons`}
					columns={headCells(locales.data)}
					onEditAction={e => navigate(e.id.toString())}
					type="lesson"
					checkboxSelection={false}
				/>
			</ThemeProvider>
		</Box>
	</Box>
}