import {FC, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {AdminHeader} from "../../AdminHeader";
import {IAdminUsersResponseEntry} from "../../../../types/admin";
import {Check, Error} from "@mui/icons-material";
import {AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/admin";
import {useNavigate} from "react-router-dom";
import {adminTheme} from "../../../../templates/adminTheme";
import {convertSecondsToHHMM} from "../../../../utils";
import {CustomToolbar} from "../../../../components/admin/bulkEnrollment/CustomToolbar";
import {GRID_CHECKBOX_SELECTION_COL_DEF, GridSelectionModel, useGridApiRef} from "@mui/x-data-grid-pro";


const useStyles = makeStyles()((_theme) => ({
	adminUsersList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

const headCells: AdminTableProps<"users", IAdminUsersResponseEntry>["columns"] = ([
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		type: "string",
		hideable: false
	},
	{
		field: "firstname",
		flex: 1,
		headerName: "First Name",
		type: "string"
	},
	{
		field: "lastname",
		flex: 1,
		headerName: "Last Name",
		type: "string"
	},
	{
		field: "email",
		flex: 1,
		headerName: "Email",
		type: "string"
	},
	{
		field: "phone",
		flex: 1,
		headerName: "Phone",
		type: "string"
	},
	{
		field: "jobCategory",
		flex: 1,
		headerName: "Job Category",
		type: "string"
	},
	{
		field: "jobTitle",
		flex: 1,
		headerName: "Job Title",
		type: "string"
	},
	{
		field: "workload",
		flex: 1,
		type: "number",
		headerName: "Workload",
		headerAlign: "left",
		align: "left",
		renderCell: (e) => <Box>
			{e.value > 0 && `+${e.value}`}
			{e.value < 0 && <Error />}
			{e.value === 0 && <Check />}
		</Box>
	},
	{
		field: "timeSpentOnTraining",
		flex: 1,
		type: "number",
		headerName: "Time in training (HH:MM)",
		headerAlign: "left",
		align: "left",
		filterable: false,
		valueGetter: (e) => e.value ? convertSecondsToHHMM(e.value) : "0"
	},
	{
		field: "streak",
		flex: 1,
		type: "number",
		headerName: "Streak",
		headerAlign: "left",
		align: "left",
		renderCell: (e) => {
			let textColor
			switch (true) {
				case e.value === 0:
					textColor = adminTheme.palette.error.main
					break;
				case e.value > 0 && e.value <= 4:
					textColor = adminTheme.palette.warning.main
					break;
				case e.value > 4:
					textColor = adminTheme.palette.success.main
					break;
			}
			return <Box color={textColor} component="span">{e.value}</Box>
		}
	},
	{
		field: "completedModules",
		flex: 1,
		type: "number",
		headerAlign: "left",
		align: "left",
		headerName: "Completed Lessons"
	},
	{
		field: "overdue",
		flex: 1,
		type: "number",
		headerName: "Overdue Lessons",
		headerAlign: "left",
		align: "left",
		renderCell: (e) => {
			let textColor
			switch (true) {
				case e.value === 0:
					textColor = adminTheme.palette.success.main
					break;
				case e.value > 0 && e.value <= 4:
					textColor = adminTheme.palette.warning.main
					break;
				case e.value > 4:
					textColor = adminTheme.palette.error.main
					break;
			}
			return <Box color={textColor} component="span">{e.value}</Box>
		}
	},
	{
		field: "createdAt",
		flex: 1,
		type: "date",
		headerName: "Created",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	}
]);

export const AdminUsersList: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const [bulkCompletionModalOpen, setBulkCompletionModalOpen] = useState<boolean>(false);
	const [selected, setSelected] = useState<GridSelectionModel>([]);
	const apiRef = useGridApiRef();

	return (
		<Box className={classes.adminUsersList}>
			<AdminHeader title="Employees" subtitle="Manage users, enroll them into courses and check their capacity" />
			<AdminTable<"users", IAdminUsersResponseEntry>
				apiRef={apiRef}
				url="/api/user"
				onEditAction={e => navigate("/admin/users/" + e.id.toString())}
				columns={headCells}
				type="users"
				disableColumnResize={false}
				// components={{Toolbar: (): JSX.Element => (<CustomToolbar exportButton columnsButton></CustomToolbar>)}}
				selectionModel={selected}
				onSelectionModelChange={e => setSelected(e)}
				initialState={{
					columns: {
						columnVisibilityModel: {
							createdAt: false,
							phone: false,
							workload: false
						}}}}
			/>
		</Box>
	);
}