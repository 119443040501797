import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Badge, Box, LinearProgress, Typography} from "@mui/material";
import {IAdminUsersAnnouncementsResponseEntry} from "../../../../types/admin";
import {useNavigate} from "react-router-dom";
import {ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {useApi} from "@plumeuk/shapeshift-identity";
import {AdminHeader} from "../../AdminHeader";
import {AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/admin";
import {GRID_CHECKBOX_SELECTION_COL_DEF} from "@mui/x-data-grid-pro";

const useStyles = makeStyles()((theme) => ({
	adminAnnouncementsList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	},
	tableBadge: {
		padding: theme.spacing(0.2, 1.25)
	},
	readDistribution: {
		width: "100%",
		display: "flex",
		gap: theme.spacing(2),
		"& > p":{
			width: theme.spacing(4),
			textAlign: "center"
		},
		"& > div":{
			width: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center"
		}
	}
}));

const headCells = (locales?: ILocalizationEntity[]): AdminTableProps<"lesson", IAdminUsersAnnouncementsResponseEntry>["columns"] => ([
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		type: "string",
		hideable: false
	},
	{
		field: "title",
		type: "string",
		flex: 1,
		headerName: "Title"
	},
	{
		field: "scheduledDeliveryDate",
		type: "string",
		flex: 1,
		headerName: "Delivery Date"
	},
	{
		field: "_status",
		type: "string",
		flex: 1,
		headerName: "Status",
		renderCell: e => { // To do: move badge output to its own component
			const {classes} = useStyles();

			if (!e.row.publishedAt) {
				return (
					<Badge className={classes.tableBadge} color="info">
						Draft
					</Badge>
				);
			}
			if (!e.row.scheduledDeliveryDate) {
				return (
					<Badge className={classes.tableBadge} color="info">
						Inactive
					</Badge>
				);
			}
			if (new Date(e.row.scheduledDeliveryDate).getTime() > new Date().getTime()) {
				return (
					<Badge className={classes.tableBadge} color="warning">
						Scheduled
					</Badge>
				);
			}
			return (
				<Badge className={classes.tableBadge} color="success">
					Live
				</Badge>
			);
		}
	},
	{
		field: "author",
		type: "string",
		flex: 1,
		headerName: "Author"
	},
	{
		field: "_read",
		type: "string",
		flex: 1,
		headerName: "Read Distribution",
		renderCell: e => {
			const {classes} = useStyles();
			const progress = (e.row.completeCount / e.row.audienceCount) * 100;
			return <Box className={classes.readDistribution}><Box><LinearProgress sx={{width: "100%"}} value={progress} variant="determinate" /></Box><Typography>{progress}%</Typography></Box>
		}
	}
]);

export const AdminAnnouncementList: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const [locales] = useApi<ILocalizationEntity[]>("/api/settings/i18n");

	return (
		<Box className={classes.adminAnnouncementsList}>
			<AdminHeader
				title="Announcements"
				subtitle="Create and send messages to your company employees"
				actionButtons={[{label: "Create New", onClick: () => navigate("create")}]}
			/>
			<AdminTable<"lesson", IAdminUsersAnnouncementsResponseEntry>
				url="/api/announcement"
				columns={headCells(locales.data)}
				onEditAction={e => navigate(e.id.toString())}
				type="lesson"
			/>
		</Box>
	);
}