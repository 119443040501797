import Box from "@mui/material/Box";
import {FC, useCallback, useContext, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {Modal} from "@plumeuk/shapeshift-common/v2";
import {Button, Typography} from "@mui/material";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {cohortBaseUrl} from "../../../constants";

interface IProps {
	open: boolean,
	onClose: (reload?: boolean) => void,
	selected: {type: string, id: number, courseSlug: string}[],
	userId: number
}

const useStyles = makeStyles()((theme) => ({
	adminBulkUserTrainingCompletedModal: {
		padding: theme.spacing(3),
		display: "flex",
		gap: theme.spacing(2),
		flexDirection: "column"
	}
}));

export const AdminBulkUserTrainingCompletedModal: FC<IProps> = ({open, selected, userId, onClose}) => {
	const {classes} = useStyles();
	const {notify} = useContext(NotificationContext);
	const [completionResponse, submitCompletionApi] = useApi<boolean>()

	const handleSubmitLessonCompletion = useCallback(() => {
		if(!open)
			return;

		submitCompletionApi({
			url: `${cohortBaseUrl}/users/${userId}/modules/complete`,
			method: "PUT",
			data: {
				modules: selected
			}
		})
	}, [open])

	useEffect(() => {
		if(completionResponse.statusCode === 200){
			notify("", "Successfully updated users lesson completions", INotificationSeverity.success, 5000)
			onClose(true)
		}
		else if(completionResponse.statusCode){
			notify("", "Failed to set users lesson completion", INotificationSeverity.error, 5000)
			onClose()
		}
	}, [completionResponse])

	return (
		<Modal
			open={open}
			footerButtons={[
				<Button key={1} onClick={() => onClose()}>CANCEL</Button>,
				<Button key={2} onClick={() => handleSubmitLessonCompletion()}>CONFIRM</Button>
			]}
			onClose={() => onClose()}
			aria-labelledby="modal-modal-title"
			title="Confirm Bulk Completion"
			aria-describedby="modal-modal-description"
		>
			<Box className={classes.adminBulkUserTrainingCompletedModal}>
				<Typography variant="h5">Confirm Lesson Completion</Typography>
				<Typography variant="body1">
				Please confirm that you want to complete the selected {selected.length > 1 ? "lessons" : "lesson"}.
				</Typography>
				<Typography>
					<li>[{selected.length}] {selected.length > 1 ? "lessons" : "lesson"} selected</li>
				</Typography>
			</Box>
		</Modal>
	);
}