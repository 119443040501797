import {FC, useContext, useEffect, useState} from "react";
import {Box, BoxProps} from "@mui/material";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, GridColumns, GridToolbar} from "@mui/x-data-grid-pro";
import {makeStyles} from "tss-react/mui";
import {IReccomendedCourse} from "../../../../../types/course";
import {Refresh} from "@mui/icons-material";
import {CustomToolbar} from "../../../../../components/admin/bulkEnrollment/CustomToolbar";

interface ICustomProps {
	userId: number
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

const useStyles = makeStyles()((theme) => ({
	tableRoot: {
		cursor: "default",
		".MuiChip-root": {
			height: "24px"
		}
	},
	toolbar: {
		display: "flex",
		"& svg": {
			fontSize: "18px"
		}
	}
}))

const headCells: GridColumns<{id: number} & IReccomendedCourse> = ([
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		type: "string",
		hideable: false
	},
	{
		field: "course",
		flex: 1,
		headerName: "Course",
		type: "string",
		valueGetter: (e) => e.value.title
	},
	{
		field: "type",
		flex: 1,
		headerName: "Type",
		type: "string"
	},
	{
		field: "score",
		flex: 1,
		headerName: "Weighting",
		type: "number"
	}

])

export const UserTrainingPoolTab:FC<IProps> = ({userId, ...baseProps}) => {
	const [data, getData] = useApi<IReccomendedCourse[]>()
	const [rows, setRows] = useState<({id: number} & IReccomendedCourse)[]>()
	const [batchActionModal, setBatchActionModal] = useState<{label: string, open: boolean, selected: number[]}>({label: "", open: false, selected: []});
	const {classes} = useStyles();
	const [enrollmentResponse, runEnrollment] = useApi<any>()
	const {notify} = useContext(NotificationContext)

	useEffect(() => {
		getData(`/api/user/admin/${userId}/reccomendedCourses`)
	}, [userId])

	useEffect(() => {
		if(enrollmentResponse.statusCode === 200){
			console.log(enrollmentResponse.data)
			notify("", "Successfully refreshed trainingfeed", INotificationSeverity.success, 5000)
			getData()
		}
	}, [enrollmentResponse])

	useEffect(() => {
		setRows(data?.data?.map(e => ({...e, id: e.course.id})))
	}, [data])


	return <Box {...baseProps}>
		<Box>
			<DataGridPro<{id: number} & IReccomendedCourse>
				className={classes.tableRoot}
				initialState={{
					sorting: {
						sortModel: [{field: "title", sort: "desc"}]
					}
				}}
				aria-label="training feed pool table"
				loading={data.isLoading}
				pagination
				disableColumnFilter
				rowCount={data?.data?.length ?? 0}
				components={{Toolbar: (): JSX.Element => (<CustomToolbar>
					<Box onClick={() => runEnrollment({url: `/api/user/admin/${userId}/refreshTrainingFeed`, method: "PUT", cache: false})}><Refresh /> REFRESH TRAINING FEED</Box>
				</CustomToolbar>)}}
				rows={rows ?? []}
				columns={headCells}
				rowsPerPageOptions={[5, 10, 20, 100]}
				checkboxSelection
				autoHeight
			/>
		</Box>
	</Box>
}