import {FC} from "react";
import Main from "./Main";
import {NotificationDrawer} from "@plumeuk/shapeshift-common/pageBase";
import {PageBaseProvider} from "./contexts/pageBaseContext";
//icons
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import RefreshIcon from "@mui/icons-material/Refresh";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PersonIcon from "@mui/icons-material/Person";
import ChairAltIcon from "@mui/icons-material/ChairAlt";
import EngineeringIcon from "@mui/icons-material/Engineering";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DoneIcon from "@mui/icons-material/Done";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import {IconSearch} from "./icons/IconSearch";
import CheckIcon from "@mui/icons-material/Check";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import {IconProvider} from "@plumeuk/shapeshift-identity";
import {IIconContext} from "@plumeuk/shapeshift-identity/types/IICon";
import {TrainingfeedProvider} from "./contexts/trainingFeedContext";
import {Announcement, AttachFile, ClearAll, Expand} from "@mui/icons-material";
import {IconNotificationUnread} from "./icons/IconNotificationUnread";
import {IconNotification} from "./icons/IconNotification";
import {SocketProvider} from "./contexts/socketContext";
import {LocaleProvider} from "./contexts/localeContext";
import {defaultIconProviderIcons} from "@plumeuk/shapeshift-common/icon"

import {LicenseInfo} from "@mui/x-license";
import {BrowserRouter} from "react-router-dom";
import {AnnouncementProvider} from "./contexts/announcementContext";

const MUI_LICENSE = process.env.REACT_APP_MUI_LICENSE
if(!MUI_LICENSE)
	throw new Error("Failed to get REACT_APP_MUI_LICENSE env")

LicenseInfo.setLicenseKey(MUI_LICENSE);


const icons: Omit<IIconContext, "icon"> = {
	...defaultIconProviderIcons,
	menuIcon: <MenuIcon />,
	logoutIcon: <LogoutIcon />,
	attachFileOutlinedIcon: <AttachFileOutlinedIcon />,
	circleIcon: <CircleIcon />,
	lockIcon: <LockIcon />,
	checkCircleOutlinedIcon: <CheckCircleOutlinedIcon />,
	chevronRightIcon: <ChevronRightIcon />,
	fileCopyIcon: <FileCopyIcon />,
	downloadForOfflineIcon: <DownloadForOfflineIcon />,
	expandIcon: <Expand/>,
	expandMoreIcon: <ExpandMoreIcon />,
	restoreFromTrashIcon: <RestoreFromTrashIcon />,
	refreshIcon: <RefreshIcon />,
	cancelScheduleSendIcon: <CancelScheduleSendIcon />,
	deleteIcon: <DeleteIcon />,
	contentPasteIcon: <ContentPasteIcon />,
	personIcon: <PersonIcon />,
	chairAltIcon: <ChairAltIcon />,
	engineeringIcon: <EngineeringIcon />,
	visibilityIcon: <VisibilityIcon />,
	visibilityOffIcon: <VisibilityOffIcon />,
	localOfferOutlinedIcon: <LocalOfferOutlinedIcon />,
	arrowBackIcon: <ArrowBackIcon />,
	arrowForwardIcon: <ArrowForwardIcon />,
	doneIcon: <DoneIcon />,
	sendOutlinedIcon: <SendOutlinedIcon />,
	closeIcon: <CloseIcon />,
	checkCircleIcon: <CheckCircleIcon />,
	errorIcon: <ErrorIcon />,
	cancelIcon: <CancelIcon />,
	infoIcon: <InfoIcon />,
	searchIcon: <IconSearch />,
	navigateNextIcon: <NavigateNextIcon />,
	starBorderIcon: <StarBorderIcon />,
	starIcon: <StarIcon />,
	editIcon: <EditIcon />,
	notificationsIcon: <IconNotification />,
	notificationsUnreadIcon: <IconNotificationUnread />,
	checkIcon: <CheckIcon />,
	keyboardArrowRightIcon: <KeyboardArrowRightIcon />,
	dragIndicatorIcon: <DragIndicatorIcon />,
	autoFixNormalIcon: <AutoFixNormalIcon />,
	attachIcon: <AttachFile />,
	clearAllIcon: <ClearAll />
}

export const App: FC = () => (
	<BrowserRouter>
		<LocaleProvider>
			<IconProvider icons={icons}>
				<PageBaseProvider>
					<SocketProvider>
						<TrainingfeedProvider>
							<AnnouncementProvider>
								<>
									<NotificationDrawer/>
									<Main />
								</>
							</AnnouncementProvider>
						</TrainingfeedProvider>
					</SocketProvider>
				</PageBaseProvider>
			</IconProvider>
		</LocaleProvider>
	</BrowserRouter>

);

export default App;