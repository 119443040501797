import {FC, useContext} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Drawer, Typography} from "@mui/material";
import {palette} from "../../constants";
import {IconAdminPlus} from "../../icons/IconAdminPlus";
import {IconAdminCourses} from "../../icons/IconAdminCourses";
import {IconAdminReports} from "../../icons/IconAdminReports";
import {IconAdminUsers} from "../../icons/IconAdminUsers";
import {IconPlayButton} from "../../icons/IconPlayButton";
import {IconAdminQuestion} from "../../icons/IconAdminQuestion";
import {IconSettings} from "../../icons/IconSettings";
import {Link, useLocation} from "react-router-dom";
import {PageBaseContext} from "../../contexts/pageBaseContext";
import {IconAnnouncement} from "../../icons/IconAnnouncement";

const useStyles = makeStyles()((theme) => ({
	sideMenu: {
		width: "283px",
		height: "100%",
		position: "fixed",
		"[class*=' MuiDrawer-paper']": {
			paddingTop: "0 !important"
		}
	},
	drawer: {
		width: "283px !important",
		height: "100%",
		overflowY: "auto",
		background: palette.black,
		transition: "all .3s ease-in-out",
		"& .MuiDrawer-paper": {
			position: "relative",
			height: "auto",
			backgroundColor: "#191919",
			borderRight: "1px solid rgba(255,255,255,0.1)",
			paddingTop: theme["toolbarHeight"],
			transition: "all .3s ease-in-out",
			width: "283px",
			overflow: "hidden",
			zIndex: theme.zIndex.appBar - 1,
			boxSizing: "border-box"
		}
	},
	header: {
		boxSizing: "border-box",
		padding: theme.spacing(3, 2),
		margin: theme.spacing(0, 3),
		borderBottom: "1px solid " + palette.grey10
	},
	companyName: {
		fontSize: "20px",
		marginTop: "8px",
		marginBottom: "4px",
		letterSpacing: "0.15px"
	},
	sectionTitle: {
		color: palette.grey70,
		fontSize: "12px",
		marginBottom: "8px",
		textTransform: "uppercase",
		letterSpacing: "2px"
	},
	sideMenuList: {
		"& a": {
			textDecoration: "none",
			color: theme.palette.common.white
		},
		padding: "8px 16px",
		margin: "32px 24px",
		display: "flex",
		flexDirection: "column",
		gap: "24px"
	},
	sideMenuSection: {
		display: "flex",
		flexDirection: "column",
		marginBottom: "38px",
		gap: "8px"
	},
	sideMenuLink: {
		cursor: "pointer",
		fontSize: "16px",
		letterSpacing: "0.15px",
		fontWeight: 400,
		display: "inline-flex",
		padding: "12px 0",
		gap: "28px",
		alignItems: "center",
		transition: "0.35s",
		position: "relative",
		"&:after": {
			content: "''",
			opacity: "0",
			position: "absolute",
			left: "-16px",
			bottom: "-3px",
			width: "0px",
			height: "4px",
			background: palette.orange,
			transition: "0.35s"
		},
		"& path": {
			fill: palette.grey60,
			transition: "0.35s"
		}
	},
	activeLink: {
		position: "relative",
		"&:after": {
			opacity: "1",
			width: "calc(100% + 32px)",
			transition: "0.35s"
		},
		"& path": {
			transition: "0.35s",
			fill: theme.palette.primary.main
		}
	},
	iconAdminPlus: {
		width: "14px",
		height: "14px"
	}
}));

export const AdminSideMenuContent: FC = () => {
	const {cx, classes} = useStyles();
	const location = useLocation();
	const [pageBaseContext] = useContext(PageBaseContext);

	const isActive = (e: string): boolean => {
		return location.pathname.startsWith(e)
	}

	const linkElement = (link: string, name: string, icon: JSX.Element): JSX.Element =>
		<Link to={link}>
			<Box className={cx(classes.sideMenuLink, isActive(link) ? classes.activeLink : undefined )}>{icon}{name}</Box>
		</Link>

	return (
		<Box className={classes.sideMenu}>
			<Drawer
				className={classes.drawer}
				variant="permanent"
			>
				<Box className={classes.header}>
					<Typography className={classes.companyName}>{(pageBaseContext.ownedCohort && pageBaseContext.ownedCohort?.title) ?? ".."}</Typography>
					<Typography className={classes.sectionTitle}>Content Manager</Typography>
				</Box>
				<Box className={classes.sideMenuList}>
					<Box className={classes.sideMenuSection}>
						<Typography className={classes.sectionTitle}>CREATION</Typography>
						{linkElement("courses/create", "Create Course", <IconAdminPlus className={classes.iconAdminPlus} />)}
					</Box>
					<Box className={classes.sideMenuSection}>
						<Typography className={classes.sectionTitle}>MANAGEMENT</Typography>
						{linkElement("/admin/users", "Employees", <IconAdminUsers />)}
						{linkElement("/admin/courses", "Courses", <IconAdminCourses />)}
						{linkElement("/admin/quizzes", "Quizzes", <IconAdminQuestion />)}
						{linkElement("/admin/lessons", "Lessons", <IconPlayButton />)}
						{linkElement("/admin/announcements", "Announcements", <IconAnnouncement />)}
					</Box>
					<Box className={classes.sideMenuSection}>
						<Typography className={classes.sectionTitle}>GENERAL</Typography>
						{/* {linkElement("support", "Support", <IconAdminSupport />)} */}
						{linkElement("/admin/settings", "Content Settings", <IconSettings />)}
					</Box>
				</Box>
			</Drawer>
		</Box>
	);
}