import {VideoPlayer} from "@plumeuk/shapeshift-common/videoPlayer";
import {useContext, useEffect, useRef, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {ILesson} from "@plumeuk/shapeshift-types";
import {ReactPlayerProps} from "react-player";
import {forwardRef} from "react";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {PlayerAPI} from "bitmovin-player/modules/bitmovinplayer-core";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {useDepLog} from "@plumeuk/shapeshift-common/hooks";

const useStyles = makeStyles()((theme) => ({
	videoPlayerBackdrop: {
		position: "relative",
		width: "100%",
		paddingBottom: "56.25%",
		height: 0,
		overflow: "hidden",
		background: "black",
		boxSizing: "border-box",
		left: 0,
		top: 0,
		right: "0px",
		[theme.breakpoints.down("sm")]: {
			height: "250px"
		},

		"#bitmovin-player": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			objectFit: "cover"
		}
	},
	videoPlayer: {
		zIndex: 0,
		background: "black",
		position: "absolute",
		opacity: 0,
		left: 0,
		transition: "opacity .5s ease-in",
		right: "0px",
		height: "100% !important"

	},
	videoPlayerSpacer: {
		height: "550px",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			height: "250px"
		}
	}
}));

interface IPropsCustom {
	lesson?: ILesson,
	bitmovinPlayer?:PlayerAPI | null
}

export type IProps = Omit<ReactPlayerProps, "url"> & IPropsCustom;

export const LessonVideoPlayer = forwardRef<VideoPlayerRef, IProps>(function LessonVideoPlayer({lesson, currentTime, bitmovinPlayer, ...props}, ref) {
	const {classes} = useStyles();
	const [videoReady, setVideoReady] = useState(false);
	const {notify} = useContext(NotificationContext);

	const handleReady = ():void => {
		props?.onReady?.()
		setVideoReady(true)
	}

	const changeByCurrentTime = useRef<boolean>(true);
	useEffect(() => {
		changeByCurrentTime.current = true;
	}, [currentTime])


	useEffect(() => {
		if(!bitmovinPlayer)
			return;

		const originalSeek = bitmovinPlayer?.seek;

		const newSeek = (time: number, issuer?: string): boolean => {
			if(!lesson || !videoReady || !bitmovinPlayer || lesson.complete || currentTime === time){
				return (originalSeek.apply(bitmovinPlayer, [time, issuer]));
			}

			const diff = time - bitmovinPlayer.getCurrentTime();
			if (diff < 0) {
				return (originalSeek.apply(bitmovinPlayer, [time, issuer]));
			}

			notify("", "Skipping is not permitted for incomplete lessons", INotificationSeverity.warning, 5000, false)
			return false;
		}
		bitmovinPlayer.seek = newSeek;


	}, [bitmovinPlayer, lesson?.complete, videoReady]);

	useEffect(() => {
		changeByCurrentTime.current = false;
	}, [videoReady])

	if(!lesson?.videoUrl) return <></>

	return (
		<>
			<div className={classes.videoPlayerBackdrop}>
				<VideoPlayer
					moduleVideoType={lesson.videoType}
					ref={ref}
					onReady={handleReady}
					className={classes.videoPlayer}
					style={{opacity: videoReady ? 1 : 0}}
					url={lesson.videoUrl}
					controls={true}
					currentTime={currentTime}
					providerDetails={bitmovinPlayer ? {bitmovin:{player: bitmovinPlayer}} : undefined}
				/>
			</div>
		</>
	);
});