import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {IAdminUsersLessonsResponseEntry} from "../../../../types/admin";
import {useNavigate} from "react-router-dom";
import {ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {useApi} from "@plumeuk/shapeshift-identity";
import {AdminHeader} from "../../AdminHeader";
import {AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/admin";
import {convertSecondsToHHMMSS} from "../../../../utils";
import {GRID_CHECKBOX_SELECTION_COL_DEF} from "@mui/x-data-grid-pro";

const useStyles = makeStyles()((_theme) => ({
	adminLessonsList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

const headCells = (locales?: ILocalizationEntity[]): AdminTableProps<"lesson", IAdminUsersLessonsResponseEntry>["columns"] => ([
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		type: "string",
		hideable: false
	},
	{
		field: "title",
		type: "string",
		flex: 1,
		headerName: "Title"
	},
	{
		field: "videoDuration",
		type: "string",
		flex: 1,
		headerName: "Duration",
		valueGetter: (e) => e.value ? convertSecondsToHHMMSS(e.value) : ""
	},
	{
		field: "updatedAt",
		type: "string",
		flex: 1,
		headerName: "Last Modified",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "publishedAt",
		type: "string",
		flex: 1,
		headerName: "Published",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "languages",
		type: "string",
		flex: 1,
		headerName: "Supported Languages",
		valueGetter: ({value}: {value: IAdminUsersLessonsResponseEntry["languages"]}) => value?.map(e => locales?.find(x => x.code === e)?.name)?.join(", ")
	}
]);

export const AdminLessonsList: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const [locales] = useApi<ILocalizationEntity[]>("/api/settings/i18n");

	return (
		<Box className={classes.adminLessonsList}>
			<AdminHeader
				title="Lessons"
				subtitle="Manage lessons"
				actionButtons={[{label: "Create New", onClick: () => navigate("create")}]}
			/>
			<AdminTable<"lesson", IAdminUsersLessonsResponseEntry>
				url="/api/strapi-plugin-shapeshift-lms/cohort/lesson"
				columns={headCells(locales.data)}
				onEditAction={e => navigate(e.id.toString())}
				enableDelete
				type="lesson"
			/>
		</Box>
	);
}