import {FC, useEffect, useState} from "react";
import {Box, BoxProps} from "@mui/material";
import {AdminHeader} from "../../../AdminHeader";
import {DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, GridColumns} from "@mui/x-data-grid-pro";
import {useApi} from "@plumeuk/shapeshift-identity";
import {IAdminUserRecentActivity} from "../../../../../types/admin";
import {makeStyles} from "tss-react/mui";
import {CustomToolbar} from "../../../../../components/admin/bulkEnrollment/CustomToolbar";

interface ICustomProps {
	userId: number
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

const useStyles = makeStyles()((theme) => ({
	tableRoot: {
		cursor: "default"
	}
}))

const headCells: GridColumns<IAdminUserRecentActivity["data"][0]> = ([
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		type: "string",
		hideable: false
	},
	{
		field: "title",
		flex: 1,
		headerName: "Lesson",
		type: "string"
	},
	{
		field: "videoLength",
		flex: 1,
		headerName: "Duration",
		type: "string",
		renderCell: e => {
			if (!e.value) {
				return null
			}
			const minutes = Math.floor(e.value / 60);
			const seconds = e.value % 60;
			return minutes > 0 ? `${minutes} min ${seconds} sec` : `${seconds} sec`;
		}
	},
	{
		field: "completedAt",
		flex: 1,
		headerName: "Watched on",
		type: "date",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "courseTitle",
		flex: 1,
		headerName: "Parent Course",
		width: 200,
		type: "string"
	}
])

export const UserWatchHistoryTab:FC<IProps> = ({userId, ...baseProps}) => {
	const [batchActionModal, setBatchActionModal] = useState<{label: string, open: boolean, selected: number[]}>({label: "", open: false, selected: []});
	const [rows, getRows] = useApi<IAdminUserRecentActivity>()
	const {classes} = useStyles();

	useEffect(() => {
		getRows(`/api/user/admin/${userId}/recentActivity?pageSize=1000`)
	}, [userId])


	return <Box {...baseProps}>
		<AdminHeader title="Lessons Recently Watched" />
		<Box>
			<DataGridPro<IAdminUserRecentActivity["data"][0]>
				className={classes.tableRoot}
				initialState={{
					sorting: {
						sortModel: [{field: "completedAt", sort: "desc"}]
					}
				}}
				aria-label="admin table"
				loading={rows.isLoading}
				pagination
				rowCount={rows?.data?.totalCount ?? 0}
				rows={rows?.data?.data ?? []}
				columns={headCells}
				rowsPerPageOptions={[5, 10, 20, 100]}
				components={{Toolbar: CustomToolbar}}
				checkboxSelection
				autoHeight
			/>
		</Box>
	</Box>
}