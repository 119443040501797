import {CourseSideMenuContent as CommonCourseSideMenuContent} from "@plumeuk/shapeshift-common/course";
import {moduleSlim, moduleTypeSlim} from "@plumeuk/shapeshift-types/ICourse";
import {FC, useCallback, useContext, useEffect, useState} from "react";
import {useMatch, useNavigate, useParams} from "react-router-dom";
import {CurriculumContext} from "../../contexts/curriculumContext";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {palette} from "../../constants";
import {TrainingFeedSideMenuContent} from "../../components/trainingFeedSideMenuContent/TrainingFeedSideMenuContent";
import {CourseCurriculumModule} from "../../components/module/CourseCurriculumModule";
import CourseProgressionSummary from "./CourseProgressionSummary";
import {LocaleContext} from "../../contexts/localeContext";

const useStyles = makeStyles()((theme) => ({
	sideMenu: {
		"[class*='moduleTitle']": {
			color: "white"
		},
		borderRadius: "16px",
		margin: "15px",
		padding: "15px 25px",
		background: "#282828"
	},
	tabs: {
		height: "70px",
		minHeight: "70px",
		borderBottom: palette.grey10 + " 1px solid",
		width: "100%",
		"& button": {
			width: "50%"
		}
	},
	moduleTab: {
		fontSize: "17px",
		background: "transparent !important"
	},
	moduleItem: {
		margin: "10px 0 12px"
	},
	courseSideMenuModule: {
	}
}));

interface ICourseSideMenuContentProps {
	setSideMenuOpen?: (open: boolean) => void;
}

export const CourseSideMenuContent: FC<ICourseSideMenuContentProps> = ({setSideMenuOpen}) => {
	const [{locale}] = useContext(LocaleContext);
	const mq = useMq();
	const {classes, cx} = useStyles();
	const pageIsModule = useMatch("/course/:courseSlug/:moduleType/:moduleSlug/*");
	const pageIsFeed = useMatch("/feed/*");
	const pageIsAnnouncement = useMatch("/feed/announcement/*");
	const moduleSlug = pageIsModule?.params.moduleSlug;
	const moduleType = pageIsModule?.params.moduleType;
	const {notify} = useContext(NotificationContext);
	const {courseSlug} = useParams();
	const navigate = useNavigate();
	const {curriculumState, curriculumDispatch} = useContext(CurriculumContext);
	const [activeModule, setActiveModule] = useState<moduleSlim>();
	const [tab, setTab] = useState<"training-feed" | "course">(pageIsFeed ? "training-feed" : "course");

	useEffect(() => {
		if(moduleSlug && moduleType)
			setActiveModule({slug: moduleSlug, type: moduleType as moduleTypeSlim});
	}, [moduleSlug, moduleType]);

	const handleActiveModuleCallback = useCallback((module: ICourseCurriculumModule): void => {
		navigate(`/course/${courseSlug}/${module.type}/${module.slug}`);//, {replace: !pageIsModule}

		if (mq.mobile) {
			setSideMenuOpen?.(false);
		}
	}, [navigate, setSideMenuOpen]);

	const handleSetModuleComplete = useCallback((module: moduleSlim, status: boolean): void => {
		curriculumDispatch({type: "setModuleComplete", module, status})
	}, [curriculumDispatch]);

	const handleModuleForbidden = useCallback((moduleRequest: ICourseCurriculumModule, nextModule?: ICourseCurriculumModule): void => {
		notify(`Lessons must be completed in sequence. Next up is ${nextModule?.title}`, "Hey now, slow down!",INotificationSeverity.warning, 5000);
	}, [curriculumDispatch]);

	return (
		<>
			{<Tabs className={classes.tabs} value={tab} onChange={(_e, v) => setTab(v)}>
				<Tab className={classes.moduleTab} label="Training Feed" value="training-feed"/>
				{!pageIsAnnouncement && <Tab className={classes.moduleTab} label="Course Content" value="course"/>}
			</Tabs>}
			{tab === "course" &&
				<CommonCourseSideMenuContent
					locale={locale}
					className={classes.sideMenu}
					data={curriculumState}
					courseSlug={courseSlug}
					onActiveChange={handleActiveModuleCallback}
					onModuleComplete={handleSetModuleComplete}
					onModuleForbidden={handleModuleForbidden}
					levelIndentSize={28}
					activeModule={activeModule}
					componentOverrides={{
						courseProgressionSummary: (p) => <CourseProgressionSummary {...p}/>,
						module: (p) => {
							if(p.module.type === "section")
								return <></>

							return <CourseCurriculumModule
								courseSlug={courseSlug ?? ""}
								onClick={() => p.setActive(p.module)}
								className={cx(classes.moduleItem)}
								module={p.module}
							/>
						}
					}}
				/>
			}
			{tab === "training-feed" &&
				<TrainingFeedSideMenuContent />
			}
		</>
	);
}