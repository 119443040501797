import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {AdminAnnouncementList} from "./AdminAnnouncementList";
import {AdminAnnouncementPage} from "./AdminAnnouncementPage";
import {AdminAnnouncementLandingPage} from "./AdminAnnouncementLandingPage";

const useStyles = makeStyles()((theme) => ({
	adminLessonsPage: {

	}
}));

export const AdminAnnouncementsPage: FC = () => {
	const {classes} = useStyles();

	return (
		<Box className={classes.adminLessonsPage}>
			<Routes>
				<Route index element={<AdminAnnouncementList />}/>
				<Route path="create" element={<AdminAnnouncementPage />} />
				<Route path=":announcementId" element={<AdminAnnouncementLandingPage />} />
				<Route path=":announcementId/edit" element={<AdminAnnouncementPage />} />
			</Routes>
		</Box>
	);
}