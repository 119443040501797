import {useCourseCurriculum} from "@plumeuk/shapeshift-common/hooks";
import {DevLog} from "@plumeuk/shapeshift-common/common";
import {moduleSlim} from "@plumeuk/shapeshift-types/ICourse";
import {createContext, useReducer, ReactElement, Reducer, useEffect, Dispatch, FC, useContext} from "react";
import {useMatch, useNavigate} from "react-router-dom";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {ICourseCurriculum} from "@plumeuk/shapeshift-types";

const initialState: {curriculumState: CurriculumContextState; curriculumDispatch: Dispatch<CurriculumContextAction>} = {curriculumState: undefined, curriculumDispatch: () => {}};
const CurriculumContext = createContext(initialState);

type CurriculumContextState = ICourseCurriculum | undefined

type CurriculumContextAction = {
	type: "set";
	payload?: ICourseCurriculum;
} | {
	type: "setModuleComplete",
	module: moduleSlim,
	status: boolean
}
| {
	type: "setCourseSlug",
	slug: string,
}

interface IProps {
	children: ReactElement,
	courseSlug?: string
}

const CurriculumProvider: FC<IProps> = ({children, courseSlug}) => {
	const {course, getCourse, setModuleComplete, apiResult} = useCourseCurriculum(courseSlug)
	const {notify} = useContext(NotificationContext);
	const navigate = useNavigate();
	const pageIsFeed = useMatch("/feed/*");

	const [curriculumState, curriculumDispatch] = useReducer<Reducer<CurriculumContextState, CurriculumContextAction>>((state: CurriculumContextState, action: CurriculumContextAction) => {
		switch (action.type) {
			case "set": {
				return action.payload;
			}
			case "setModuleComplete": {
				if(courseSlug)
					setModuleComplete(action.module, action.status);
				return state;
			}
			case "setCourseSlug": {
				getCourse(action.slug)
				return state;
			}
			default:
				return state;
		}
	}, initialState.curriculumState);

	//below is for debugging only
	useEffect(() => {
		if(curriculumState)
			DevLog("UPDATE TO CURRICULUM STATE", curriculumState);
	}, [curriculumState]);

	useEffect(() => {
		if(courseSlug)
			getCourse(courseSlug);
	}, [courseSlug]);

	useEffect(() => {
		if(course)
			curriculumDispatch({type: "set", payload: course});
	}, [course]);

	useEffect(() => {
		if(curriculumState && curriculumState.percentCompleted === 100 && !pageIsFeed) {
			navigate(`/course/${courseSlug}/complete`);
			return;
		}
	}, [curriculumState]);

	useEffect(() => {
		if(apiResult.isError) {
			notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000);
		}
	}, [apiResult])

	return (
		<CurriculumContext.Provider value={{curriculumState, curriculumDispatch}}>
			{children}
		</CurriculumContext.Provider>
	);
};

export {
	CurriculumContext,
	CurriculumProvider
};