import {FC, useCallback, useContext, useEffect} from "react";
import Box from "@mui/material/Box";
import {makeStyles} from "tss-react/mui";
import {Modal} from "@plumeuk/shapeshift-common/v2";
import {Button, Typography} from "@mui/material";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {cohortBaseUrl} from "../../../constants";

interface IProps {
	open: boolean,
	userId: number,
	onClose: (reload?: boolean) => void,
	selected: number[]
}

const useStyles = makeStyles()((theme) => ({
	adminBulkUserTrainingCompletedModal: {
		padding: theme.spacing(3),
		display: "flex",
		gap: theme.spacing(2),
		flexDirection: "column"
	}
}));

export const AdminBulkUserUnenrollModal: FC<IProps> = ({userId, open, selected, onClose}) => {
	const {classes} = useStyles();
	const {notify} = useContext(NotificationContext);
	const [unenrollResponse, submitUnenrollApi] = useApi<any>() //> any to do

	const handleSubmitUnenrollCourses = useCallback(() => {
		if(!open)
			return;

		submitUnenrollApi({
			url: `${cohortBaseUrl}/users/${userId}/courses/unenroll`,
			method: "PUT",
			data: {
				courses: selected
			}
		})
	}, [open])

	useEffect(() => {
		if(unenrollResponse.statusCode === 200){
			notify("", "Successfully updated users active courses", INotificationSeverity.success, 5000)
			onClose(true)
		}
		else if(unenrollResponse.statusCode){
			notify("", "Failed to unenroll users courses", INotificationSeverity.error, 5000)
			onClose()
		}
	}, [unenrollResponse])

	return (
		<Modal
			open={open}
			footerButtons={[
				<Button key={1} onClick={() => onClose()}>CANCEL</Button>,
				<Button key={2} onClick={() => handleSubmitUnenrollCourses()}>CONFIRM</Button>
			]}
			onClose={() => onClose()}
			aria-labelledby="modal-modal-title"
			title="Confirm Course Unenrollment"
			aria-describedby="modal-modal-description"
		>
			<Box className={classes.adminBulkUserTrainingCompletedModal}>
				<Typography variant="h5">Confirm Course Unenrollment</Typography>
				<Typography variant="body1">
				Please confirm that you want to unenroll the user from the {selected.length > 1 ? "courses" : "course"} selected.
				</Typography>
				<Typography>
					<li>[{selected.length}] {selected.length > 1 ? "courses" : "course"} selected</li>
				</Typography>
			</Box>
		</Modal>
	);
}