import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Typography} from "@mui/material";
import {AdminHeader} from "../../AdminHeader";
import {useNavigate} from "react-router-dom";
import {useApi} from "@plumeuk/shapeshift-identity";
import {IAdminUsersCoursesResponseEntry, ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {AdminTable, AdminTableProps, ConfirmDeleteEntityDialog} from "@plumeuk/shapeshift-common/admin";
import {GRID_CHECKBOX_SELECTION_COL_DEF} from "@mui/x-data-grid-pro";

const useStyles = makeStyles()((_theme) => ({
	adminCoursesList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

const headCells = (locales?: ILocalizationEntity[]): AdminTableProps<"course", IAdminUsersCoursesResponseEntry>["columns"] => ([
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		type: "string",
		hideable: false
	},
	{
		field: "title",
		type: "string",
		flex: 1,
		headerName: "Course"
	},
	{
		field: "tags",
		type: "string",
		flex: 1,
		headerName: "Tags",
		valueGetter: ({value}: {value: IAdminUsersCoursesResponseEntry["tags"]}) => value?.join(", ")
	},
	{
		field: "moduleCount",
		type: "string",
		flex: 1,
		headerName: "Lessons"
	},
	{
		field: "enrollmentCount",
		type: "string",
		flex: 1,
		headerName: "Enrolled Employees"
	},
	{
		field: "completionCount",
		type: "string",
		flex: 1,
		headerName: "Course Completions"
	},
	{
		field: "contentType",
		type: "string",
		flex: 1,
		headerName: "Content Type"
	},
	{
		field: "updatedAt",
		type: "string",
		flex: 1,
		headerName: "Last Modified",
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "publishedAt",
		type: "string",
		flex: 1,
		headerName: "Published",
		filterable: true,
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "languages",
		type: "string",
		flex: 1,
		headerName: "Supported Languages",
		valueGetter: ({value}: {value: IAdminUsersCoursesResponseEntry["languages"]}) => value?.map(e => locales?.find(x => x.code === e)?.name)?.join(", ")
	}
]);

export const AdminCoursesList: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const [locales] = useApi<ILocalizationEntity[]>("/api/settings/i18n");

	return (
		<Box className={classes.adminCoursesList}>
			<AdminHeader
				title="Courses"
				subtitle="Manage courses, enroll them into courses and check their capacity"
				actionButtons={[{label: "Create New", onClick: () => navigate("create")}]}
			/>
			<AdminTable<"course", IAdminUsersCoursesResponseEntry>
				url="/api/strapi-plugin-shapeshift-lms/cohort/course"
				columns={headCells(locales.data)}
				onEditAction={e => navigate(e.id.toString())}
				enableDelete
				type="course"
				componentOverrides={{
					deleteDialog: deleteProps => <ConfirmDeleteEntityDialog
						{...deleteProps}
						componentOverrides={{
							content: (deleteProps?.entity?.enrollmentCount && deleteProps.entity?.enrollmentCount >= 1) ? <Typography variant="body1">You will be making an assigned course unavailable.</Typography> : undefined
						}}
					/>
				}}
				initialState={{
					columns: {
						columnVisibilityModel: {
							tags: false,
							updatedAt: false
						}}}}
			/>
		</Box>
	);
}